import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { BaseSlots } from './Slots';
import { ISlotsComponentProps } from './types';
import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';

export const SlotsResponsive = (props: ISlotsComponentProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <>
      <DesktopComponent>
        <SlotsWrapperLandscape>
          <BaseSlots {...props} deviceType={Device.Desktop} />
        </SlotsWrapperLandscape>
      </DesktopComponent>
      <TabletLandscapeComponent>
        <SlotsWrapperLandscape>
          <BaseSlots {...props} deviceType={Device.Tablet_Landscape} />
        </SlotsWrapperLandscape>
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <SlotsPortraitWrapper $isMinimized={isScoreboardOpen} $deviceType={Device.Tablet_Portrait}>
          <SlotsPortraitContentWrapper>
            <BaseSlots {...props} deviceType={Device.Tablet_Portrait} />
          </SlotsPortraitContentWrapper>
        </SlotsPortraitWrapper>
      </TabletPortraitComponent>
      <MobileComponent>
        <SlotsPortraitWrapper $isMinimized={isScoreboardOpen} $deviceType={Device.Mobile}>
          <SlotsPortraitContentWrapper>
            <BaseSlots {...props} deviceType={Device.Mobile} />
          </SlotsPortraitContentWrapper>
        </SlotsPortraitWrapper>
      </MobileComponent>
    </>
  );
};

const SlotsWrapperLandscape = styled.div`
  position: absolute;
  bottom: 134rem;
  left: 50%;
  height: 178rem;
  width: 1006rem;
  transform: translateX(-50%);
`;

const SlotsPortraitContentWrapper = styled.div`
  /* transform: scale(0.96); */
  transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
    ? 'scale(0.96)'
    : 'none'};
`;

const SlotsPortraitWrapper = styled.div<{ $isMinimized: boolean; $deviceType: Device }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { background } }) => css`
    background: ${background.slotsMobileDefault};
  `}

  ${({ $deviceType, $isMinimized }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: ${$isMinimized ? '276rem' : '406rem'};

        ${SlotsPortraitContentWrapper} {
          width: 722rem;
          height: ${$isMinimized ? '245rem' : '375rem'};
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      if (isOldBrowserIOS() && isInIframe()) {
        return css`
          height: ${$isMinimized ? '132rem' : '264rem'};

          ${SlotsPortraitContentWrapper} {
            width: 346rem;
            height: ${$isMinimized ? '114rem' : '260rem'};
          }
        `;
      }
      return css`
        height: ${isOldBrowserIOS()
          ? $isMinimized
            ? '127rem'
            : '275rem'
          : $isMinimized
            ? '134rem'
            : '275rem'};

        ${SlotsPortraitContentWrapper} {
          width: 346rem;
          height: ${isOldBrowserIOS()
            ? $isMinimized
              ? '114rem'
              : '260rem'
            : $isMinimized
              ? '119rem'
              : '260rem'};
        }
      `;
    }
  }}
`;

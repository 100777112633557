import { ActionType, createReducer } from 'typesafe-actions';
import {
  updateCurrentJackpotUserPointsJP,
  updateWinners,
  setCurrentJackpot,
  setPointsInRow,
  setUserInfo,
  updateJackpot,
  setNextJackpot,
  updateNextJackpot,
  setStageJackpot,
  setJackpotWon,
  setCountdownJackpot,
  setJackpotWonSum,
  setJackpotWonWinners,
  setIsUserWonInJP,
  setShowJPPopup,
  setNewCurrentJackpot,
  setInfoJackpotDraw,
  updateNextJackpotUserPointsJP,
  setInfoDisplayingJackpotWin,
} from './actions';
import { IJackpotState } from './types';

export const jackpotState: IJackpotState = {
  currentJackpot: null,
  nextJackpot: null,
  currentJackpotPoints: {
    value: 0,
    jackpotId: null,
  },
  nextJackpotPoints: {
    value: 0,
    jackpotId: null,
  },
  isUserWonInJP: false,
  showJPPopup: false,
  countdownJackpot: null,
  winnersListJackpot: null,
  stage: null,

  pointsInRow: 0,
  roundInRow: 0,
  username: null,
  uid: null,
  amountJackpotUserWon: null,
  currencyJackpotUserWon: null,
  newCurrentJackpot: null,
  jackpotAmount: null,
  winDuration: null,
  jackpotCurrency: null,
};

const actions = {
  updateWinners,
  updateCurrentJackpotUserPointsJP,
  updateNextJackpotUserPointsJP,
  setCurrentJackpot,
  updateJackpot,
  setNextJackpot,
  updateNextJackpot,
  setPointsInRow,
  setUserInfo,
  setStageJackpot,
  setJackpotWon,
  setJackpotWonSum,
  setJackpotWonWinners,
  setCountdownJackpot,
  setIsUserWonInJP,
  setShowJPPopup,
  setNewCurrentJackpot,
  setInfoDisplayingJackpotWin,
  setInfoJackpotDraw,
};

export const jackpotReducer = createReducer<IJackpotState, ActionType<typeof actions>>(jackpotState)
  .handleAction(updateCurrentJackpotUserPointsJP, (state: IJackpotState, { payload: points }) => ({
    ...state,
    currentJackpotPoints: points,
  }))
  .handleAction(updateNextJackpotUserPointsJP, (state: IJackpotState, { payload: points }) => ({
    ...state,
    nextJackpotPoints: points,
  }))
  .handleAction(setCurrentJackpot, (state, { payload: currentJackpot }) => ({
    ...state,
    currentJackpot,
    newCurrentJackpot: null,
  }))
  .handleAction(updateJackpot, (state, { payload }) => {
    if (!state.currentJackpot) {
      return state;
    }

    return {
      ...state,
      currentJackpot: { ...state.currentJackpot, ...payload },
    };
  })
  .handleAction(setNextJackpot, (state, { payload: nextJackpot }) => ({
    ...state,
    nextJackpot,
  }))
  .handleAction(updateNextJackpot, (state, { payload }) => {
    if (!state.nextJackpot) {
      return state;
    }

    return {
      ...state,
      nextJackpot: { ...state.nextJackpot, ...payload },
    };
  })
  .handleAction(setPointsInRow, (state, { payload: { pointsInRow, roundInRow } }) => ({
    ...state,
    pointsInRow,
    roundInRow,
  }))
  .handleAction(setUserInfo, (state, { payload: { username, uid } }) => ({
    ...state,
    username,
    uid,
  }))
  .handleAction(setStageJackpot, (state, { payload: stage }) => ({
    ...state,
    stage,
  }))
  .handleAction(
    setJackpotWon,
    (
      state,
      { payload: { players: winnersListJackpot, amount: amountJackpotUserWon, currency } },
    ) => ({
      ...state,
      winnersListJackpot,
      amountJackpotUserWon,
      currencyJackpotUserWon: currency,
    }),
  )
  .handleAction(setJackpotWonSum, (state, { payload: { sum: amountJackpotUserWon } }) => ({
    ...state,
    amountJackpotUserWon,
  }))
  .handleAction(setJackpotWonWinners, (state, { payload: { players: winnersListJackpot } }) => ({
    ...state,
    winnersListJackpot,
  }))
  .handleAction(setCountdownJackpot, (state, { payload: countdownJackpot }) => ({
    ...state,
    countdownJackpot,
  }))
  .handleAction(setIsUserWonInJP, (state, { payload: isUserWonInJP }) => ({
    ...state,
    isUserWonInJP,
  }))
  .handleAction(setShowJPPopup, (state, { payload: showJPPopup }) => ({
    ...state,
    showJPPopup,
    stage: null,
  }))
  .handleAction(setNewCurrentJackpot, (state, { payload: newCurrentJackpot }) => ({
    ...state,
    newCurrentJackpot,
  }))
  .handleAction(setInfoDisplayingJackpotWin, (state, { payload: { winDuration } }) => ({
    ...state,
    winDuration,
  }))
  .handleAction(setInfoJackpotDraw, (state, { payload: { jackpotAmount, jackpotCurrency } }) => ({
    ...state,
    jackpotAmount,
    jackpotCurrency,
  }));

import { combineReducers } from 'redux';
import { authReducer } from './widgets/Auth';
import { isScoreboardOpenReducer } from './widgets/Scoreboard';
import { ballsCounterReducer } from './widgets/Scoreboard/BallsCounter';
import { scoreboardReducer } from './widgets/Scoreboard/Boards';
import { tableSettingsReducer } from './widgets/TableSettings';
import { userReducer } from './widgets/User';
import { webSocketReducer } from './widgets/WebSocket';
import { winnerBallReducer } from './widgets/WinnerBall';
import { roundStateReducer } from './widgets/RoundStates';
import { roundStatusReducer } from './widgets/RoundStatuses';
import { betConfirmationReducer } from './widgets/BetConfirmation';
import { betReducer } from './widgets/Bet';
import { betRepeatReducer } from './widgets/BetRepeat';
import { betDoubleReducer } from './widgets/BetDouble';
import { toastReducer } from './widgets/Toast';
import { bettingTimeReducer } from './widgets/BettingTime';
import { activeChipReducer } from './widgets/ActiveChip';
import { winStatusReducer } from './widgets/WinStatus';
import { noticesReducer } from './widgets/Notices';
import { soundSettingsReducer } from './widgets/SoundSettings';
import { videoSettingsReducer } from './widgets/VideoSettings';
import { settingsPanelReducer } from './widgets/SettingsPanel';
import { generalSettingsReducer } from './widgets/GeneralSettings';
import { gameRulesReducer } from './widgets/GameRules';
import { roundWinnersReducer } from './widgets/WinnersTicker';
import { jackpotReducer } from './widgets/Jackpot';
import { historyReducer } from './widgets/History';

export const rootReducer = combineReducers({
  webSocketReducer,
  authReducer,
  activeChipReducer,
  tableSettingsReducer,
  userReducer,
  isScoreboardOpenReducer,
  ballsCounterReducer,
  scoreboardReducer,
  winnerBallReducer,
  winStatusReducer,
  roundStateReducer,
  roundStatusReducer,
  betReducer,
  betConfirmationReducer,
  betDoubleReducer,
  betRepeatReducer,
  toastReducer,
  bettingTimeReducer,
  noticesReducer,
  soundSettingsReducer,
  videoSettingsReducer,
  generalSettingsReducer,
  gameRulesReducer,
  settingsPanelReducer,
  roundWinnersReducer,
  jackpotReducer,
  historyReducer,
});

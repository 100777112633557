import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export enum LANGUAGES {
  'en' = 'en',
  'zh' = 'zh',
  'hi' = 'hi',
  'es' = 'es',
  'pt' = 'pt',
  'fr' = 'fr',
  'ru' = 'ru',
  'tl' = 'tl',
  'id' = 'id',
}

export const defaultLanguage = LANGUAGES.en;

export const supportedLanguages: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: 'English',
  [LANGUAGES.zh]: '中文',
  [LANGUAGES.hi]: 'हिन्दी',
  [LANGUAGES.es]: 'Español',
  [LANGUAGES.pt]: 'Português',
  [LANGUAGES.fr]: 'Français',
  [LANGUAGES.ru]: 'Русский',
  [LANGUAGES.tl]: 'Tagalog',
  [LANGUAGES.id]: 'Bahasa',
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
    debug: false,
    fallbackLng: defaultLanguage,
    supportedLngs: Object.values(LANGUAGES).map((lng) => lng),
    load: 'currentOnly',
    lowerCaseLng: true,
    compatibilityJSON: 'v3',
  });

export default i18n;

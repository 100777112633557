import { colors } from 'constants/theme';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { isIOS } from 'react-device-detect';
import styled, { css, keyframes } from 'styled-components';
import { Device, SLOTS } from 'types';

export const slotWinnerAnimation = keyframes`
  0% {
    fill-opacity: 0;
  }

  33% {
    fill-opacity: 0;
  }

  67% {
    fill-opacity: 1;
  }

  100% {
    fill-opacity: 1;
  }
`;

export const slotWinnerOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  67% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

export const slotWinnerAnimationRule = css`
  & #winner_path {
    animation: ${slotWinnerAnimation} 0.6s ease-out 5 forwards alternate;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const SlotsWrapper = styled.svg`
  position: relative;
  overflow: visible;
`;

export const SlotTitle = styled.p``;

export const SlotValue = styled.p``;

const slotPaddingsMinimized = {
  [SLOTS.player]: '8px 0 0 6.5px;',
  [SLOTS.tie]: '8px 0 0 0;',
  [SLOTS.banker]: '8px 6.5px 0 0;',
};

const slotPaddingsMinimizedWithBets = {
  [SLOTS.player]: '8px 0 0 30px;',
  [SLOTS.tie]: '8px 0 0 50px;',
  [SLOTS.banker]: '8px 6.5px 0 30px;',
};

const slotNameColor = {
  [SLOTS.player]: colors.SlotBlue,
  [SLOTS.tie]: colors.SlotGold,
  [SLOTS.banker]: colors.SlotRed,
};

const slotPaddingsDefault = {
  [SLOTS.player]: '28px 0 0 25px;',
  [SLOTS.tie]: '40px 0 0 0;',
  [SLOTS.banker]: '28px 25px 0 0;',
};

const slotPaddingsDefaultWithBets = {
  [SLOTS.player]: '28px 0 0 25px;',
  [SLOTS.tie]: '28px 0 0 0;',
  [SLOTS.banker]: '28px 25px 0 0;',
};

export const SlotInnerWrapper = styled.div<{
  $slot: SLOTS;
  $deviceType: Device;
  $isMinimized: boolean;
  $hasPlacedBet: boolean;
}>`
  display: flex;
  text-transform: uppercase;

  ${({ $slot }) => css`
    ${SlotTitle} {
      color: ${slotNameColor[$slot]};
    }
    ${SlotValue} {
      color: ${colors.SlotText};
    }
  `}

  ${({ $deviceType, $isMinimized, $slot, $hasPlacedBet }) => {
    if ($deviceType === Device.Tablet_Portrait || $deviceType === Device.Mobile) {
      return css`
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      `;
    }

    return $isMinimized
      ? css`
          flex-direction: row;
          justify-content: ${$hasPlacedBet ? 'flex-start' : 'center'};
          padding: ${$hasPlacedBet
            ? slotPaddingsMinimizedWithBets[$slot]
            : slotPaddingsMinimized[$slot]};
        `
      : css`
          flex-direction: column;
          align-items: center;
          /* padding: ${slotPaddingsDefault[$slot]}}; */
          padding: ${$hasPlacedBet
            ? slotPaddingsDefaultWithBets[$slot]
            : slotPaddingsDefault[$slot]};
        `;
  }}

  ${({ $deviceType, $isMinimized, $hasPlacedBet }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        justify-content: ${$isMinimized && $hasPlacedBet ? 'flex-start' : 'center'};

        ${SlotTitle} {
          font-size: 24px;
          line-height: 17px;
          letter-spacing: 2px;
          margin-top: ${$isMinimized && $hasPlacedBet ? '24px' : '0'};
        }

        ${SlotValue} {
          margin-top: 16px;
          font-size: ${$isMinimized ? '16px' : '20px'};
          line-height: 13px;
          letter-spacing: 2px;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        justify-content: ${$isMinimized && $hasPlacedBet ? 'flex-start' : 'center'};
        margin-top: 0;

        ${SlotTitle} {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 1px;
          margin-top: ${$isMinimized && $hasPlacedBet ? (isIOS ? '4rem' : '0px') : '0'};
        }

        ${SlotValue} {
          margin-top: ${$isMinimized ? '-1px' : '4px'};
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 2px;
        }
      `;
    }

    return css`
      ${SlotTitle} {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 3px;
      }

      ${SlotValue} {
        margin-left: ${$isMinimized ? '16px' : '0'};
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 2px;
      }
    `;
  }}
`;

export const JackpotBox = styled.div<{
  $deviceType: Device;
  $isMobileMinimized: boolean;
  $hasJackpot?: boolean;
  $hasStageCountdown: boolean;
}>`
  width: 100%;
  height: 50rem;
  /* 50rem */
  position: absolute;
  /* background: #41b6e620; */
  display: flex;

  align-items: center;
  /* padding: 0 165rem; */
  box-sizing: border-box;

  /* ${({ $hasStageCountdown }) => css`
    padding: ${$hasStageCountdown ? '0 140rem' : '0 165rem'};
  `}; */
  gap: 10rem;
  justify-content: center;

  /* ${({ $hasJackpot }) => css`
    justify-content: ${$hasJackpot ? 'space-between' : 'center'};
  `}; */

  svg {
    display: flex;
    max-width: 18%;
  }

  ${({ $deviceType, $isMobileMinimized }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        /* width: 346rem; */
        padding: 0 44rem;
        /* height: 48rem; */
        height: 104rem;

        svg {
          max-width: 18%;
        }
      `;
    }
  }}

  ${({ $deviceType, $isMobileMinimized, $hasJackpot }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        padding: 0 16rem;
        height: ${$isMobileMinimized ? '54rem' : '78rem'};

        justify-content: ${$hasJackpot ? 'space-between' : 'center'};

        svg {
          max-width: 22%;
        }
      `;
    }
  }}
`;

export const BrandName = styled.span<{
  $color: string;
}>`
  color: ${({ $color }) => $color};
`;

export const JackpotName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JackpotMobile = styled.span<{
  $hasJackpot?: boolean;
  $deviceType: Device;
  $isMobileMinimized: boolean;
  $numberCharacters: number;
}>`
  letter-spacing: 3rem;
  text-transform: uppercase;
  color: #fff7eb;
  font-style: normal;
  font-weight: 700;
  font-size: 22rem;
  margin: 0 6rem;

  ${({ $deviceType, $hasJackpot, $isMobileMinimized }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        font-size: 24rem;
        left: 94rem;
        margin: 0 4rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        letter-spacing: 2rem;
        font-size: ${$hasJackpot ? '14rem' : '16rem'};
        left: 94rem;
        margin: 0 2rem;
      `;
    }
  }}

  ${({ $deviceType, $numberCharacters, $hasJackpot }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        top: 266rem;
        font-size: ${() => {
          if ($numberCharacters > 13) {
            const baseFontSize = 18;
            const decreaseFactor = 1.3;
            const extraCharacters = $numberCharacters;
            const steps = Math.floor(extraCharacters / 3);
            return `${baseFontSize - steps * decreaseFactor}rem`;
          } else {
            return $hasJackpot ? '14rem' : '16rem';
          }
        }};

        @media only screen and (max-width: 375px) {
          font-size: ${() => {
            if ($numberCharacters > 9) {
              const baseFontSize = 16;
              const decreaseFactor = 1.4;
              const extraCharacters = $numberCharacters;
              const steps = Math.floor(extraCharacters / 3);
              return `${baseFontSize - steps * decreaseFactor}rem`;
            } else {
              return $hasJackpot ? '14rem' : '16rem';
            }
          }};
        }
      `;
    }
  }}
`;

export const StageText = styled.span`
  color: #fff7eb;
  font-size: 14rem;
  font-weight: 400;
  line-height: 16rem; /* 114.286% */
  text-transform: uppercase;
  fill: #fff7eb;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
`;

export const Text = styled.p`
  font-weight: 400;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const StageWrapper = styled.div<{ $deviceType: Device }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  height: 36rem;
  margin-right: 16rem;

  svg {
    max-width: 100% !important;
    height: 100%;
    width: auto;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        ${Text} {
          font-size: 12px;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 334rem;
        height: 28rem;
        width: auto;
        left: 20rem;

        ${Text} {
          font-size: 12rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: calc(56.25vw - 44rem);
        height: 24rem;
        width: auto;

        svg {
          height: 100%;
          width: auto;
        }
        @media only screen and (max-width: 430px) {
          top: ${isOldBrowserIOS() ? '145rem' : 'calc(56.25vw - 20rem)'};
        }

        ${Text} {
          font-size: 10rem;
        }
      `;
    }
  }};
`;

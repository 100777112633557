import { delay, put, select } from 'redux-saga/effects';
import i18n from 'i18n';
import * as Sentry from '@sentry/react';

import { updateActiveChipAction } from 'core/widgets/ActiveChip/actions';
import { addConfirmedBetAction, clearAllBetsAction } from 'core/widgets/Bet/actions';
import { getBetSum } from 'core/widgets/Bet/utils';
import {
  saveBettingTimeToStoreAction,
  startDecrementTimeLeftAction,
} from 'core/widgets/BettingTime/actions';
import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { VideoQualities, StreamNamesType } from 'core/widgets/VideoSettings/types';
import { setInitialCounterStateAction } from 'core/widgets/Scoreboard/BallsCounter/actions';
import { setInitialScoreboardStateAction } from 'core/widgets/Scoreboard/Boards/actions';
import { saveTableSettingsToStoreAction } from 'core/widgets/TableSettings/actions';
import { setBalanceAction, setTotalBetAction } from 'core/widgets/User/actions';
import { Notifications } from 'types';
import { TOTAL_SCOREBOARD_ROUNDS } from 'core/widgets/Scoreboard/types';
import { setStreamNamesAction } from 'core/widgets/VideoSettings/action';
import { confirmedBetSelector } from 'core/widgets/Bet/selectors';
import { BetType } from 'core/widgets/Bet/types';
import {
  setCurrentJackpot,
  setNextJackpot,
  setStageJackpot,
  setUserInfo,
} from 'core/widgets/Jackpot/actions';
import { setUserInfoAuth } from 'core/widgets/Auth/actions';
import { ACTIONS, IPlayerIdStage, PositionJackpot } from 'core/widgets/Jackpot/types';
import { RestService } from 'services/RestAPIService';
import { OnPlayerConnectedData } from '../types';

export function* onPlayerConnectedSaga({
  type: notificationType,
  data: result,
}: OnPlayerConnectedData) {
  try {
    const { user, state, bets, mode, jackpotOn } = result;
    const {
      balance,
      betsToken,
      apiToken,
      username,
      uid,
      minBet: minBetLimit,
      maxBet: maxBetLimit,
      lang,
      currency,
      operatorName,
    } = user;

    const {
      table: { table_id: tableId, displaying_win_ball: displayingWinBall },
      dealer: { nickname: dealerNickname },
      round,
      status: roundStatus,
      scoreBoard: winnerBallsData,
      jackpots,
    } = state;

    const operatorId = operatorName.replace(/\s+/g, '-').toLowerCase();

    if (notificationType === Notifications.PLAYER_CONNECTED) {
      const { stream_high, stream_medium, stream_low } = state.table;
      const confirmedBets: BetType[] = yield select(confirmedBetSelector);
      Sentry.setTag('uid', uid);
      Sentry.setTag('game_id', round?.gameId);
      Sentry.setTag('operator_id', operatorName);

      yield put(setUserInfo({ username, uid }));
      yield put(
        setUserInfoAuth({
          username,
          uid,
          currency: currency?.toUpperCase(),
          mode,
          jackpotOn,
          lang,
          operatorId,
        }),
      );

      yield put(
        saveTableSettingsToStoreAction({
          tableId,
          gameId: round?.gameId || null,
          dealerNickname,
          minBetLimit,
          maxBetLimit,
          displayingWinBall,
        }),
      );
      yield put(changeRoundStateAction({ roundState: roundStatus }));

      yield put(setBalanceAction(balance));

      yield put(setTotalBetAction(0));

      if (
        roundStatus === RoundStates.BettingTimeStarted &&
        round?.finishBettingTime !== undefined
      ) {
        const date = new Date(round.finishBettingTime);
        const dateNow = new Date();
        const difference = Math.floor((date.getTime() - dateNow.getTime()) / 1000);

        yield put(
          saveBettingTimeToStoreAction({
            bettingTimeEnd: round.finishBettingTime,
            bettingTime: difference,
          }),
        );
        yield put(startDecrementTimeLeftAction());
      }

      const language = localStorage.getItem('language')
        ? JSON.parse(localStorage.getItem('language') as string)
        : null;

      if (!language) {
        i18n.changeLanguage(lang);
      }

      if (confirmedBets.length) {
        yield put(clearAllBetsAction());
      }

      const isLastScoreboardRoundDrawn = winnerBallsData.length === TOTAL_SCOREBOARD_ROUNDS;
      const isResultConfirmedLastRoundStatus =
        isLastScoreboardRoundDrawn && roundStatus === RoundStates.ResultConfirmed;

      if (
        (winnerBallsData.length && !isLastScoreboardRoundDrawn) ||
        isResultConfirmedLastRoundStatus
      ) {
        yield put(setInitialCounterStateAction({ winnerBallsData }));
        yield put(setInitialScoreboardStateAction({ winnerBallsData }));
      }

      if (bets !== undefined && roundStatus !== RoundStates.ResultConfirmed) {
        const betId = new Date().getTime();
        const totalBet = getBetSum([bets]);

        yield put(addConfirmedBetAction({ id: betId, body: bets }));
        yield put(setTotalBetAction(totalBet));
      }

      localStorage.setItem(`${operatorId}_${tableId}_rest_auth_token`, betsToken);
      localStorage.setItem(`${operatorId}_${tableId}_api_token`, apiToken);

      RestService.setTableID(tableId as string);
      RestService.setOperatorID(operatorId as string);

      yield put(updateActiveChipAction());

      if (stream_high && stream_medium && stream_low) {
        const streamNames: StreamNamesType = {
          [VideoQualities.High]: stream_high,
          [VideoQualities.Medium]: stream_medium,
          [VideoQualities.Low]: stream_low,
        };

        yield put(setStreamNamesAction(streamNames));
      }

      if (jackpots?.length) {
        const currentJackpot = jackpots?.find(
          (jackpot) => jackpot.position === PositionJackpot.CURRENT,
        );
        const nextJackpot = jackpots?.find((jackpot) => jackpot.position === PositionJackpot.NEXT);

        if (nextJackpot) {
          yield put(setNextJackpot(nextJackpot));
        }

        if (currentJackpot) {
          yield put(setCurrentJackpot(currentJackpot));

          if (currentJackpot.draw) {
            const playersObject = currentJackpot.draw.players?.length
              ? currentJackpot.draw.players?.reduce(
                  (acc, player) => ({
                    ...acc,
                    [player.uuid]: player,
                  }),
                  {} as { [key: string]: IPlayerIdStage },
                )
              : null;

            if (playersObject) {
              yield put(
                setStageJackpot({ playersObject, stageNumber: currentJackpot.draw.stageNumber }),
              );
            } else {
              yield put(
                setStageJackpot({
                  playersObject: null,
                  stageNumber: currentJackpot.draw.stageNumber,
                }),
              );
            }
          }

          yield delay(3000);

          yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

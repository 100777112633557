import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from 'i18n';
import { useTranslation } from 'react-i18next';

import { getGameRulesAction } from 'core/widgets/GameRules/actions';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { GameRulesButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const GameRulesButton = () => {
  const dispatch = useDispatch();

  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { i18n } = useTranslation();

  const onOpenGameRules = useCallback(() => {
    dispatch(getGameRulesAction(i18n.language as LANGUAGES));
    dispatch(manageSettingsPanelAction({ settingsPanel: SettingPanels.Rules }));
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  }, [dispatch, gameSoundMute, i18n.language, soundEffects]);

  return (
    <>
      <GameRulesButtonResponsive onOpenGameRules={onOpenGameRules} />
    </>
  );
};

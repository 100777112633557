import { ModeGame } from 'core/widgets/Auth/types';

export enum Notifications {
  PLAYER_CONNECTED = 'PLAYER_CONNECTED',
  PLAYER_CONNECTION_FAILED = 'PLAYER_CONNECTION_FAILED',
  PLAYER_UPDATED = 'PLAYER_UPDATED',
  PLAYER_WON = 'PLAYER_WON',
  PLAYER_LOST = 'PLAYER_LOST',
  PLAYER_AFK = 'PLAYER_AFK',
  BALANCE_CHANGED = 'BALANCE_CHANGED',
  ROUND_STARTED = 'ROUND_STARTED',
  ROUND_CANCELED = 'ROUND_CANCELED',
  ROUND_FINISHED = 'ROUND_FINISHED',
  DEALER_CHANGED = 'DEALER_CHANGED',
  DRAW_FINISHED = 'DRAW_FINISHED',
  DRAW_STARTED = 'DRAW_STARTED',
  RESULT_CONFIRMED = 'RESULT_CONFIRMED',
  RESULT_CHANGED = 'RESULT_CHANGED',
  BETTING_TIME_STARTED = 'BETTING_TIME_STARTED',
  BETTING_TIME_FINISHED = 'BETTING_TIME_FINISHED',
  TABLE_CLOSED = 'TABLE_CLOSED',
  NOTIFICATION = 'NOTIFICATION',
  PENDING_BETS_MADE = 'PENDING_BETS_MADE',
  DISPLAY_WINNERS = 'DISPLAY_WINNERS',

  JACKPOT_PROVIDED = 'JACKPOT_PROVIDED',
  CURRENT_JACKPOT_UPDATED = 'CURRENT_JACKPOT_UPDATED',
  NEXT_JACKPOT_UPDATED = 'NEXT_JACKPOT_UPDATED',
  POINT_AWARDED = 'POINT_AWARDED',
  PLAYER_POINTS = 'PLAYER_POINTS',

  // JACKPOT_STAGE_STARTED = 'JACKPOT_STAGE_STARTED',
  JACKPOT_PLAYER_STAGE_STARTED = 'JACKPOT_PLAYER_STAGE_STARTED',
  DISPLAY_JACKPOT_WINNERS = 'DISPLAY_JACKPOT_WINNERS',
  JACKPOT_PLAYER_WON = 'JACKPOT_PLAYER_WON',

  JACKPOT_CREATED = 'JACKPOT_CREATED',
  JACKPOT_UPDATED = 'JACKPOT_UPDATED',
  JACKPOT_CANCELED = 'JACKPOT_CANCELED',
  JACKPOT_PAUSED = 'JACKPOT_PAUSED',
  JACKPOT_UNPAUSED = 'JACKPOT_UNPAUSED',
  JACKPOT_COLLECTION_PERIOD_STARTED = 'JACKPOT_COLLECTION_PERIOD_STARTED',
  JACKPOT_COLLECTION_PERIOD_FINISHED = 'JACKPOT_COLLECTION_PERIOD_FINISHED',
  JACKPOT_DETAILS_UPDATED = 'JACKPOT_DETAILS_UPDATED',
  JACKPOT_POINT_AWARDED = 'JACKPOT_POINT_AWARDED',
  JACKPOT_PLAYER_POINTS = 'JACKPOT_PLAYER_POINTS',
  JACKPOT_STAGE_STARTED = 'JACKPOT_STAGE_STARTED',
  JACKPOT_FINISHED = 'JACKPOT_FINISHED',
  JACKPOT_STATE_UPDATED = 'JACKPOT_STATE_UPDATED',
}

export interface INotificationResponse<T> {
  data: T;
  type: Notifications;
  mode?: ModeGame;
}

export enum ClientSideNotifications {
  SOCKET_CONNECTED = 'connect',
  SOCKET_DISCONNECTED = 'disconnect',
}

import { all, call, fork, put, take } from 'redux-saga/effects';
import { activeChipRootSaga } from './widgets/ActiveChip';
import { authRootSaga } from './widgets/Auth/sagas/root';
import { betRootSaga } from './widgets/Bet';
import { betConfirmationRootSaga } from './widgets/BetConfirmation';
import { betDoubleRootSaga } from './widgets/BetDouble';
import { betRepeatRootSaga } from './widgets/BetRepeat';
import { gameRulesSaga } from './widgets/GameRules';
import { userRootSaga } from './widgets/User';
import { webSocketRootSaga } from './widgets/WebSocket';
import { watchDatesControlSaga } from './watchDatesControlSaga';
import { historySaga } from './widgets/History';
import { watchDecrementTimeLeftSaga } from './watchDecrementTimeLeftSaga';

export function* rootSaga() {
  // const taskChannel = createTaskChannel() as any;
  // yield fork(processTasks, taskChannel);

  try {
    yield all([
      webSocketRootSaga(),
      authRootSaga(),
      activeChipRootSaga(),
      userRootSaga(),
      betRootSaga(),
      betConfirmationRootSaga(),
      betDoubleRootSaga(),
      betRepeatRootSaga(),
      gameRulesSaga(),
      watchDatesControlSaga(),
      watchDecrementTimeLeftSaga(),
      historySaga(),
    ]);
  } catch (e) {
    global.console.log(e);
  }
}

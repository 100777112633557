import React from 'react';
import { Icon, IconNames, TiltedRightButton } from 'components';
import { IPortraitGameRulesButtonProps } from './types';

export const GameRulesButtonPortrait = ({
  onOpenGameRules,
  offsetLeft,
  offsetRight,
}: IPortraitGameRulesButtonProps) => (
  <TiltedRightButton onClick={onOpenGameRules} offsetRight={offsetRight} offsetLeft={offsetLeft}>
    <Icon name={IconNames.GameRule} />
  </TiltedRightButton>
);

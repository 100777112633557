import React from 'react';
import { isIOS, isMobileOnly, isSafari, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Device } from 'types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { WalletBalance } from 'widgets/WalletBalance';
import { CloseGameButton } from 'widgets/CloseGameButton';
import { GameRulesButton } from 'widgets/GameRulesButton';
import { SettingsButtonMobile } from 'widgets/SettingsButtonMobile';
import { FullScreenModeButton } from 'widgets/FullScreenModeButton';
import { WinnersButton } from 'widgets/WinnersButton';
import { HistoryButton } from 'widgets/HistoryButton';

import { TopPanelStyled } from './styles';
import { IBaseTopPanel } from './types';

export const TopPanelPortrait = ({ deviceType = Device.Mobile }: IBaseTopPanel) => {
  const isIPadNonSafari = !isSafari && isIOS && isTablet;
  const isIPhone = isIOS && isMobileOnly;
  const { jackpotOn } = useSelector(authInfoSelector);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {jackpotOn ? (
        <TopPanelStyled $deviceType={deviceType}>
          <CloseGameButton />
          <WalletBalance />
          {!(isIPadNonSafari || isIPhone) && <FullScreenModeButton />}
          {/* @TODO ADD BUTTON JP(FIX BUG)  */}
          <WinnersButton />
          <GameRulesButton />
          <SettingsButtonMobile />
          <HistoryButton />
        </TopPanelStyled>
      ) : (
        <TopPanelStyled $deviceType={deviceType}>
          <CloseGameButton />
          <WalletBalance />
          {!(isIPadNonSafari || isIPhone) && <FullScreenModeButton />}
          <GameRulesButton />
          <SettingsButtonMobile />
          <HistoryButton />
        </TopPanelStyled>
      )}
    </>
  );
};

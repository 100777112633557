import React from 'react';
import { Icon, IconNames, TiltedRightButton } from 'components';
import { IPortraitFullScreenModeButtonProps } from './types';

export const FullScreenModeButtonPortrait = ({
  onFullScreenClick,
  offsetLeft,
  offsetRight,
  isActive,
}: IPortraitFullScreenModeButtonProps) => (
  <TiltedRightButton
    onClick={onFullScreenClick}
    offsetRight={offsetRight}
    offsetLeft={offsetLeft}
    isActive={isActive}>
    <Icon name={IconNames.Fill} />
  </TiltedRightButton>
);

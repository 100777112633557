import { tableSettingsSelector } from 'core/widgets/TableSettings';
import { connectionFailedSelector, socketDisconnectedSelector } from 'core/widgets/WebSocket';
import { Loader, PopupConfirm } from 'components';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useModal } from 'hooks';
import { LoaderWrapper, LoadingText, Wrapper } from './styles';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

export const PreloaderMain = () => {
  const { t } = useTranslation();
  const tableInfo = useSelector(tableSettingsSelector);
  const isConnectionFailed = useSelector(connectionFailedSelector);
  const isSocketDisconnected = useSelector(socketDisconnectedSelector);
  const { mode } = useSelector(authInfoSelector);

  const {
    isShown: isConnectionFailedNotificationShown,
    openModal: openConnectionFailedNotification,
  } = useModal();

  const onCloseGameClick = useCallback(() => {
    if (mode === 'iframe') {
      window.parent.postMessage('GT:EXIT', '*');
    } else {
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  }, [mode]);

  useEffect(() => {
    if (isConnectionFailed) {
      openConnectionFailedNotification();
    }
  }, [openConnectionFailedNotification, isConnectionFailed]);

  if (tableInfo === null || isSocketDisconnected) {
    return (
      <Wrapper>
        {isConnectionFailedNotificationShown ? (
          <PopupConfirm onConfirm={onCloseGameClick} buttonText="buttons.close">
            {t('notices.gameAlreadyOpened')}
          </PopupConfirm>
        ) : (
          <>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
            <LoadingText>{t('notices.loading')}</LoadingText>
          </>
        )}
      </Wrapper>
    );
  }

  return null;
};

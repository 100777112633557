import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import {
  applySoundMuteAction,
  changeSoundMuteAction,
  changeSoundVolumeAction,
} from 'core/widgets/SoundSettings/action';
import { Sounds } from 'core/widgets/SoundSettings/types';
import { ISoundSettingsPanelProps } from './types';
import { SoundSettingsPanelResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const SoundSettingsPanel = ({ onCloseClick }: ISoundSettingsPanelProps) => {
  const { applied, gameSoundMute, music, soundEffects, digitalVoice, studioSound } =
    useSelector(soundSettingsSelector);

  const dispatch = useDispatch();

  const onSwitchChange = useCallback(
    (value: boolean) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }

      dispatch(changeSoundMuteAction(value));
      AudioService.changeSoundSettings(value);
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onSwitchSoundChange = useCallback(
    (mute: boolean, type: Sounds) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
      AudioService.changeSoundMuteSettings({ mute, type });
      dispatch(applySoundMuteAction({ mute, type }));
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onSliderChange = useCallback(
    (volume: number, type: Sounds) => {
      dispatch(changeSoundVolumeAction({ volume, type }));
      AudioService.changeSoundVolumeSettings({ volume, type });
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      localStorage.setItem('music', JSON.stringify(music));
      localStorage.setItem('soundEffects', JSON.stringify(soundEffects));
      localStorage.setItem('digitalVoice', JSON.stringify(digitalVoice));
      localStorage.setItem('studioSound', JSON.stringify(studioSound));
    },
    [music, soundEffects, digitalVoice, studioSound],
  );

  return (
    <>
      <SoundSettingsPanelResponsive
        value={applied.volume || 0}
        isSoundMuted={applied.mute || false}
        gameSoundMute={gameSoundMute}
        music={music}
        soundEffects={soundEffects}
        digitalVoice={digitalVoice}
        studioSound={studioSound}
        onSwitchChange={onSwitchChange}
        onSliderChange={onSliderChange}
        onCloseClick={onCloseClick}
        onSwitchSoundChange={onSwitchSoundChange}
      />
    </>
  );
};

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, supportedLanguages } from 'i18n';
import { useDispatch, useSelector } from 'react-redux';

import { autoConfirmSelector } from 'core/widgets/GeneralSettings';
import { changeAutoConfirmAction } from 'core/widgets/GeneralSettings/actions';
import { ISelectOption } from 'components/Select/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { GeneralSettingsPanelResponsive } from './Responsive';
import { IGeneralSettingsPanelProps } from './types';
import AudioService from 'services/AudioService';

export const GeneralSettingsPanel = ({ onCloseClick }: IGeneralSettingsPanelProps) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { requested: isAutoConfirmEnabled } = useSelector(autoConfirmSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const currentLanguage: ISelectOption = useMemo(
    () => ({
      label: supportedLanguages[i18n.language as LANGUAGES] || 'English',
      value: Object.prototype.hasOwnProperty.call(supportedLanguages, i18n.language)
        ? i18n.language
        : 'en',
    }),
    [i18n.language],
  );

  const languageOptions: ISelectOption[] = useMemo(
    () =>
      Object.entries(supportedLanguages).map(([shortName, name]) => ({
        label: name,
        value: shortName,
      })),
    [],
  );

  const onLanguageChange = useCallback(
    (option: ISelectOption | null) => {
      if (option) {
        i18n.changeLanguage(option.value);
        localStorage.setItem('language', JSON.stringify(option.value));
      }
    },
    [i18n],
  );

  const onAutoConfirmChange = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }

    dispatch(changeAutoConfirmAction());
  }, [dispatch, gameSoundMute, soundEffects]);

  return (
    <>
      <GeneralSettingsPanelResponsive
        isAutoConfirmEnabled={isAutoConfirmEnabled}
        onAutoConfirmChange={onAutoConfirmChange}
        currentLanguage={currentLanguage}
        languageOptions={languageOptions}
        onLanguageChange={onLanguageChange}
        onCloseClick={onCloseClick}
      />
    </>
  );
};

/* eslint-disable prefer-const */

/* eslint-disable */
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { activeChipSelector } from 'core/widgets/ActiveChip';
import { confirmedBetSelector, summaryBetSelector } from 'core/widgets/Bet';
import { clearPlacedBetAction, confirmBetAction, placeBetAction } from 'core/widgets/Bet/actions';
import { confirmationSlotSelector } from 'core/widgets/BetConfirmation';
import { SLOTS } from 'types';
import { winnerBallSelector } from 'core/widgets/WinnerBall';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { getBetSum, getSummaryBet } from 'core/widgets/Bet/utils';
import { BetType } from 'core/widgets/Bet/types';
import { SlotsResponsive } from './Responsive';
import {
  hasUserInStageSelector,
  hasUserInTop100Selector,
  isUserInStageJPSelector,
  jackpotActivitySelector,
} from 'core/widgets/Jackpot/selectors';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import AudioService from 'services/AudioService';

export const Slots = () => {
  const dispatch = useDispatch();
  const winnerBall = useSelector(winnerBallSelector);
  const roundState = useSelector(roundStateSelector);
  const currentSlot = useSelector(confirmationSlotSelector);
  const slotsData = useSelector(summaryBetSelector);

  const { currency } = useSelector(authInfoSelector);

  const activeChip = useSelector(activeChipSelector);
  const confirmedBets = useSelector(confirmedBetSelector);
  const totalConfirmedBets: BetType = getSummaryBet(confirmedBets);
  const betSum = getBetSum([totalConfirmedBets]);
  const { gameSoundMute, music, soundEffects } = useSelector(soundSettingsSelector);
  const {
    hasCollectionPeriod,
    countdownJackpot,
    minBet,
    sum,
    stage,
    currency: jackpotCurrency,
  } = useSelector(jackpotActivitySelector);
  const hasUserInStage = useSelector(hasUserInStageSelector);

  const hasUserInTop100 = useSelector(hasUserInTop100Selector);
  const isUserInStageJP = useSelector(isUserInStageJPSelector);
  const audioMusicRef = useRef(null) as any;

  const hasBets = Object.keys(slotsData).find((key: string) => {
    if ((slotsData[key as SLOTS] as number) > 0) {
      return key;
    }
    return undefined;
  }) as SLOTS;

  const onSlotClick = useCallback(
    (slot: SLOTS) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }

      if (activeChip === null) {
        return;
      }

      dispatch(placeBetAction({ slot, value: activeChip.value }));
    },
    [activeChip, dispatch, gameSoundMute, soundEffects],
  );

  const onBetConfirm = useCallback(() => {
    dispatch(confirmBetAction());

    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('confirm', soundEffects.volume);
    }
  }, [dispatch, gameSoundMute, soundEffects]);

  const onBetClear = useCallback(
    (slot: SLOTS) => {
      dispatch(clearPlacedBetAction(slot));

      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('cancel', soundEffects.volume);
      }
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onErrorClick = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('error', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  useEffect(() => {
    if (!gameSoundMute && !music.mute && AudioService.isInit()) {
      AudioService.playInBackgroundMusic('music');
    }
    if (gameSoundMute || (music.mute && AudioService.isInit())) {
      AudioService.stopBackgroundMusicSound('music');
    }
  }, [gameSoundMute, music.mute, AudioService.isInit()]);

  return (
    <>
      <SlotsResponsive
        slots={slotsData}
        currentSlot={currentSlot}
        winnerBall={roundState === RoundStates.ResultConfirmed ? winnerBall : null}
        activeChip={activeChip?.color}
        isBettingTime={roundState === RoundStates.BettingTimeStarted}
        hasConfirmedBets={Boolean(betSum > 0)}
        onSlotClick={onSlotClick}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        hasBets={hasBets}
        onErrorClick={onErrorClick}
        jackpotSum={sum ? sum : undefined}
        jackpotCurrency={jackpotCurrency}
        currency={currency}
        minBetJP={minBet?.value}
        totalConfirmedBets={totalConfirmedBets}
        isActiveSlot={Boolean(
          (Boolean(stage) && isUserInStageJP) || Boolean(hasUserInTop100 && !Boolean(stage)),
        )}
        stage={stage}
        modeOnlyOneSlotForBets={Boolean(isUserInStageJP)}
        dateTimer={countdownJackpot}
        isActiveCollectionPeriod={hasCollectionPeriod}
        hasUserInStage={hasUserInStage}
      />
    </>
  );
};

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noticeSelector } from 'core/widgets/Notices';
import { Notices as NoticeType } from 'core/widgets/Notices/types';
import { PopupConfirm } from 'components';
import { removeNoticeAction } from 'core/widgets/Notices/actions';
import { useTranslation } from 'react-i18next';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

const NOTICE_DISPLAY_TIME = 2000;

export const Notices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notification = useSelector(noticeSelector);
  const { mode } = useSelector(authInfoSelector);

  const onCloseGame = useCallback(() => {
    if (mode === 'iframe') {
      window.parent.postMessage('GT:EXIT', '*');
    } else {
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  }, [mode]);

  const onCloseNotice = useCallback(() => {
    dispatch(removeNoticeAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      notification === NoticeType.CloseTableInProgress ||
      notification === NoticeType.RoundCanceled
    ) {
      const timer = setTimeout(() => {
        dispatch(removeNoticeAction());
      }, NOTICE_DISPLAY_TIME);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, onCloseGame, notification]);

  useEffect(() => {
    if (notification === NoticeType.TableClosed) {
      const timer = setTimeout(() => {
        onCloseGame();
      }, NOTICE_DISPLAY_TIME);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onCloseGame, notification]);

  if (notification === NoticeType.RoundCanceled) {
    return (
      <PopupConfirm onConfirm={onCloseNotice} buttonText="buttons.ok">
        {t('notices.roundCanceled')}
      </PopupConfirm>
    );
  }

  if (notification === NoticeType.TableClosed) {
    return (
      <PopupConfirm onConfirm={onCloseGame} buttonText="buttons.lobby">
        <span>{t('notices.tableClosed')}</span>
      </PopupConfirm>
    );
  }

  if (notification === NoticeType.CloseTableInProgress) {
    return (
      <PopupConfirm onConfirm={onCloseNotice} buttonText="buttons.ok">
        <span>{t('notices.tableClosing')}</span>
      </PopupConfirm>
    );
  }

  if (notification === NoticeType.NoBetsMade) {
    return (
      <PopupConfirm onConfirm={onCloseGame} buttonText="buttons.lobby">
        <span>{t('notices.noBetsMade')}</span>
      </PopupConfirm>
    );
  }

  return null;
};

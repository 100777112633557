export enum ACTIONS {
  ADD_NOTICE = 'ADD_NOTICE',
  REMOVE_NOTICE = 'REMOVE_NOTICE',
}

export enum Notices {
  RoundCanceled = 'ROUND_CANCELED',
  TableClosed = 'TABLE_CLOSED',
  CloseTableInProgress = 'CLOSE_TABLE_IN_PROGRESS',
  NoBetsMade = 'NO_BETS_MADE',
}

export interface INoticesState {
  notifications: Notices[];
}

import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isActive: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52rem;
  width: 52rem;
  border-style: solid;
  border-radius: 50%;
  cursor: pointer;

  ${({ theme: { background, border } }) => css`
    border-width: 1px;
    border-color: ${border.disabled};
    background: ${background.primary};

    @media only screen and (hover: hover) {
      &:hover {
        border-width: 2px;
        border-color: ${border.accent};
        background: ${background.primary};
      }
    }
  `}

  ${({ $isActive, theme: { border } }) => {
    if ($isActive) {
      return css`
        border-width: 2px;
        border-color: ${border.accent};
        background: linear-gradient(360deg, #121011 0%, #343434 100%);
      `;
    }
  }}
`;

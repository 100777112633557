import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addScoreboardPredictionAction,
  removeScoreboardPredictionAction,
} from 'core/widgets/Scoreboard/Boards/actions';
import { winnerBallSelector } from 'core/widgets/WinnerBall';
import { WinnerBall } from 'constants/common';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { PredictionsSectionResponsive } from './Responsive';
import { PredictionBallType } from '../types';
import { ISoundSettingsState } from 'core/widgets/SoundSettings/types';
import AudioService from 'services/AudioService';

export const PredictionsSection = () => {
  const [predictedBall, setPredictedBall] = useState<PredictionBallType | null>(null);
  const blinkingTimer = useRef<ReturnType<typeof setTimeout>>();
  const drawnWinnerBall: WinnerBall | null = useSelector(winnerBallSelector);
  const dispatch = useDispatch();
  const { gameSoundMute, soundEffects }: ISoundSettingsState = useSelector(soundSettingsSelector);

  useEffect(() => {
    if (predictedBall) {
      blinkingTimer.current = setTimeout(() => {
        setPredictedBall(null);
        dispatch(removeScoreboardPredictionAction());
      }, 5000);
    }
  }, [predictedBall, dispatch]);

  useEffect(() => {
    if (drawnWinnerBall) {
      setPredictedBall(null);
      clearTimeout(blinkingTimer.current);
    }
  }, [drawnWinnerBall]);

  const predictWinnerBall = (winnerBall: PredictionBallType) => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
    if (winnerBall !== predictedBall) {
      clearTimeout(blinkingTimer.current);
      setPredictedBall(winnerBall);
      dispatch(addScoreboardPredictionAction({ winnerBall }));
    }
  };

  return (
    <>
      <PredictionsSectionResponsive predictWinnerBall={predictWinnerBall} />
    </>
  );
};

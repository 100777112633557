import React from 'react';
import { Device, IJackpotSlot } from 'types';
import { isIOS } from 'react-device-detect';
import { Wrapper } from '../styles';
import { JackpotTitleBaccaratBrand } from './JackpotTitleBaccaratBrand';
import { JackpotAmount } from './JackpotAmount';

export const SlotJackpotMaximizedMobile = ({ jackpotSum, isActiveSlot, onClick }: IJackpotSlot) => (
  <Wrapper
    $deviceType={Device.Mobile}
    $hasJackpot={Boolean(jackpotSum)}
    width="346"
    height="74"
    viewBox="0 0 346 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}>
    {isActiveSlot ? (
      <g filter="url(#filter0_biii_718_62380)">
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          fill="#121011"
        />
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          fill="url(#paint0_linear_718_62380)"
        />
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint1_linear_718_62380)"
        />
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint2_linear_718_62380)"
        />
      </g>
    ) : (
      <>
        <g filter="url(#filter0_i_518_9980)">
          <path
            d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
            fill="#121011"
          />
          <path
            d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
            fill="url(#paint0_linear_518_9980)"
          />
        </g>
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint1_linear_518_9980)"
        />
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint2_linear_518_9980)"
        />
      </>
    )}

    {isActiveSlot ? (
      <defs>
        <filter
          id="filter0_biii_718_62380"
          x="-5.15752"
          y="-7.5"
          width="356.315"
          height="86.6575"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_718_62380" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_718_62380"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.82876" />
          <feGaussianBlur stdDeviation="8.48628" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_718_62380" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-8" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.356863 0 0 0 0 0.0627451 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_718_62380"
            result="effect3_innerShadow_718_62380"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.72549 0 0 0 0 0 0 0 0 0 0.980392 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_718_62380"
            result="effect4_innerShadow_718_62380"
          />
        </filter>
        <linearGradient
          id="paint0_linear_718_62380"
          x1="128"
          y1="1"
          x2="128"
          y2="73"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="1" stopColor="#343434" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_718_62380"
          x1="173"
          y1="1"
          x2="173"
          y2="73"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_718_62380"
          x1="345"
          y1="37"
          x2="1"
          y2="37"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E6D2" />
          <stop offset="0.5" stopColor="#FFF7EB" />
          <stop offset="1" stopColor="#F1E6D2" />
        </linearGradient>
      </defs>
    ) : (
      <>
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint1_linear_1348_14202)"
        />
        <path
          d="M321 1C334.255 1 345 11.7452 345 25V73H1V25C1 11.7452 11.7452 1 25 1H179.149H321Z"
          stroke="url(#paint2_linear_1348_14202)"
        />
        <defs>
          <filter
            id="filter0_i_518_9980"
            x="0.5"
            y="0.5"
            width="345"
            height="74"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_518_9980" />
          </filter>
          <linearGradient
            id="paint0_linear_518_9980"
            x1="128"
            y1="1"
            x2="128"
            y2="73"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#343434" stopOpacity="0" />
            <stop offset="1" stopColor="#343434" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_518_9980"
            x1="173"
            y1="1"
            x2="173"
            y2="73"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF7EB" stopOpacity="0.4" />
            <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_518_9980"
            x1="345"
            y1="37"
            x2="1"
            y2="37"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F1E6D2" />
            <stop offset="0.5" stopColor="#FFF7EB" />
            <stop offset="1" stopColor="#F1E6D2" />
          </linearGradient>
        </defs>
      </>
    )}
  </Wrapper>
);

import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { GameRulesButtonPortrait } from './GameRulesButton.portrait';
import { IGameRulesButtonProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const GameRulesButtonResponsive = (props: IGameRulesButtonProps) => (
  <>
    <TabletPortraitComponent>
      <GameRulesButtonPortrait {...props} offsetRight={59} />
    </TabletPortraitComponent>
    <MobileComponent>
      <GameRulesButtonPortrait
        {...props}
        offsetRight={isOldBrowserIOS() ? 22 : 24}
        offsetLeft={'initial'}
      />
    </MobileComponent>
  </>
);

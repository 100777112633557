import React from 'react';
import { Device, IJackpotSlot } from 'types';
import { isIOS, isSafari } from 'react-device-detect';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Wrapper, StageText } from './styles';
// import { JACKPOT } from './types';
// import { JackpotTitleBaccarat } from './components/JackpotTitleBaccarat';
import { JackpotTitleBaccarat } from './components/JackpotTitleBaccarat';
import { JackpotTitleBaccaratBrand } from './components/JackpotTitleBaccaratBrand';
import { JackpotAmount } from './components/JackpotAmount';
// import { JACKPOT } from './types';
// import { JackpotAmount } from './components/JackpotAmount';
// import { JackpotAmount } from './components/JackpotAmount';

// TODO remove 'desktop' in styles during updating jackpot logic
export const SlotJackpotLandscape = ({
  jackpotSum,
  isActiveSlot,
  stage,
  dateTimer,
  onClick,
}: IJackpotSlot) => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper
      $deviceType={Device.Desktop}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="981"
      height="48"
      viewBox="0 0 981 48"
      x="12"
      y="0"
      fill="none">
      {isActiveSlot ? (
        <g filter="url(#filter0_biii_716_62363)">
          <path
            d="M385.726 0H145.489C127.093 0 108.694 1.91551 90.9645 6.82429C47.7017 18.8028 16.1681 32.7849 0 48H371.844H375.866H489H803.48H980.726C964.558 32.7849 933.024 18.8028 889.761 6.82429C872.032 1.91551 853.633 0 835.237 0H767H489H389.726H385.726Z"
            fill="#121011"
          />
          <path
            d="M385.726 0H145.489C127.093 0 108.694 1.91551 90.9645 6.82429C47.7017 18.8028 16.1681 32.7849 0 48H371.844H375.866H489H803.48H980.726C964.558 32.7849 933.024 18.8028 889.761 6.82429C872.032 1.91551 853.633 0 835.237 0H767H489H389.726H385.726Z"
            fill="url(#paint0_linear_716_62363)"
          />
          <path
            d="M145.489 1H385.726H389.726H489H767H835.237C853.566 1 871.872 2.90864 889.495 7.78803C931.079 19.3017 961.639 32.6256 978.131 47H803.48H489H375.866H371.844H2.59484C19.0868 32.6256 49.6471 19.3017 91.2314 7.78803C108.854 2.90865 127.16 1 145.489 1Z"
            stroke="url(#paint1_linear_716_62363)"
            strokeWidth="2"
          />
          <path
            d="M145.489 1H385.726H389.726H489H767H835.237C853.566 1 871.872 2.90864 889.495 7.78803C931.079 19.3017 961.639 32.6256 978.131 47H803.48H489H375.866H371.844H2.59484C19.0868 32.6256 49.6471 19.3017 91.2314 7.78803C108.854 2.90865 127.16 1 145.489 1Z"
            stroke="url(#paint2_linear_716_62363)"
            strokeWidth="2"
          />
        </g>
      ) : (
        <g filter="url(#filter0_bi_518_7535)">
          <path
            d="M385.726 0H145.489C127.093 0 108.694 1.91551 90.9645 6.82429C47.7017 18.8028 16.1681 32.7849 0 48H371.844H375.866H489H803.48H980.726C964.558 32.7849 933.024 18.8028 889.761 6.82429C872.032 1.91551 853.633 0 835.237 0H767H489H389.726H385.726Z"
            fill="#121011"
          />
          <path
            d="M385.726 0H145.489C127.093 0 108.694 1.91551 90.9645 6.82429C47.7017 18.8028 16.1681 32.7849 0 48H371.844H375.866H489H803.48H980.726C964.558 32.7849 933.024 18.8028 889.761 6.82429C872.032 1.91551 853.633 0 835.237 0H767H489H389.726H385.726Z"
            fill="url(#paint0_linear_518_7535)"
          />
          <path
            d="M145.489 1H385.726H389.726H489H767H835.237C853.566 1 871.872 2.90864 889.495 7.78803C931.079 19.3017 961.639 32.6256 978.131 47H803.48H489H375.866H371.844H2.59484C19.0868 32.6256 49.6471 19.3017 91.2314 7.78803C108.854 2.90865 127.16 1 145.489 1Z"
            stroke="url(#paint1_linear_518_7535)"
            strokeWidth="2"
          />
          <path
            d="M145.489 1H385.726H389.726H489H767H835.237C853.566 1 871.872 2.90864 889.495 7.78803C931.079 19.3017 961.639 32.6256 978.131 47H803.48H489H375.866H371.844H2.59484C19.0868 32.6256 49.6471 19.3017 91.2314 7.78803C108.854 2.90865 127.16 1 145.489 1Z"
            stroke="url(#paint2_linear_518_7535)"
            strokeWidth="2"
          />
        </g>
      )}

      {isActiveSlot ? (
        <defs>
          <filter
            id="filter0_biii_716_62363"
            x="-5.65752"
            y="-8"
            width="992.042"
            height="61.6575"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_716_62363"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_716_62363"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.82876" />
            <feGaussianBlur stdDeviation="8.48628" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_716_62363" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-8" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.356863 0 0 0 0 0.0627451 0 0 0 0 0.94902 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_716_62363"
              result="effect3_innerShadow_716_62363"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.72549 0 0 0 0 0 0 0 0 0 0.980392 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_innerShadow_716_62363"
              result="effect4_innerShadow_716_62363"
            />
          </filter>
          <linearGradient
            id="paint0_linear_716_62363"
            x1="362.071"
            y1="0"
            x2="362.071"
            y2="48"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#343434" stopOpacity="0" />
            <stop offset="1" stopColor="#343434" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_716_62363"
            x1="490.363"
            y1="0"
            x2="490.363"
            y2="48"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF7EB" stopOpacity="0.4" />
            <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_716_62363"
            x1="980.726"
            y1="24"
            x2="0"
            y2="24"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F1E6D2" />
            <stop offset="0.5" stopColor="#FFF7EB" />
            <stop offset="1" stopColor="#F1E6D2" />
          </linearGradient>
        </defs>
      ) : (
        <defs>
          <filter
            id="filter0_bi_518_7535"
            x="-5.65752"
            y="-5.65752"
            width="992.041"
            height="59.315"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_518_7535" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_518_7535"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.82876" />
            <feGaussianBlur stdDeviation="8.48628" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_518_7535" />
          </filter>
          <linearGradient
            id="paint0_linear_518_7535"
            x1="362.071"
            y1="0"
            x2="362.071"
            y2="48"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#343434" stopOpacity="0" />
            <stop offset="1" stopColor="#343434" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_518_7535"
            x1="490.363"
            y1="0"
            x2="490.363"
            y2="48"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF7EB" stopOpacity="0.4" />
            <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_518_7535"
            x1="980.726"
            y1="24"
            x2="0"
            y2="24"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F1E6D2" />
            <stop offset="0.5" stopColor="#FFF7EB" />
            <stop offset="1" stopColor="#F1E6D2" />
          </linearGradient>
        </defs>
      )}
      {/* </g> */}
    </Wrapper>
  );
};

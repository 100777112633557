import { call, fork, put } from 'redux-saga/effects';
import { webSocketService } from 'services/WebSocketService';
import { ActionType } from 'typesafe-actions';
import { connectToWebSocketAction, connectToWebSocketSuccessAction } from '../actions';
import {
  listenDealerChangedMessage,
  // listenPlayerConnectedMessage,
  listenRoundStartedMessage,
  listenDrawFinishedMessage,
  listenResultConfirmedMessage,
  // listenBettingTimeFinishedMessage,
  listenBettingTimeStartedMessage,
  // listenDrawStartedMessage,
  listenRoundCanceledMessage,
  listenTableClosedMessage,
  listenCloseTableNotificationMessage,
  listenPlayerWonMessage,
  listenPlayerLostMessage,
  listenRoundFinishedMessage,
  listenPlayerUpdatedMessage,
  listenConnectionFailedMessage,
  listenSocketСonnectedMessage,
  listenSocketDisconnectedMessage,
  listenPendingBetsMadeMessage,
  listenCloseGameNoBetsNotificationMessage,
  listenDisplayWinnersMessage,
  listenJackpotPlayerPoints,
  listenJackpotPointAwarded,
  listenJackpotCreated,
  listenJackpotStageStarted,
  listenJackpotFinished,
  listenJackpotUpdated,
  listenJackpotDetailsUpdated,
  listenJackpotPaused,
  listenJackpotUnpaused,
  listenJackpotCollectionStarted,
  listenJackpotStateUpdated,
  listenJackpotCollectionFinished,
  listenDrawStartedMessage,
  listenBettingTimeFinishedMessage,
  listenPlayerConnectedMessage,
} from '../listeners';

export function* connectToWebSocketSaga({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  payload: { authData, platformType, brand: brandUrl },
}: ActionType<typeof connectToWebSocketAction>) {
  const isConnected: boolean = yield call(() => webSocketService.isConnected());
  if (!isConnected) {
    const sessionId = authData?.sessionId;

    if (!sessionId) {
      // TODO: handle case if there is no auth data from pathname and localStorage
      return;
    }

    yield call(() => webSocketService.connect(sessionId as string, platformType));

    yield put(connectToWebSocketSuccessAction());

    // TODO: cancel
    yield fork(listenPlayerConnectedMessage);
    yield fork(listenPlayerUpdatedMessage);
    yield fork(listenPlayerWonMessage);
    yield fork(listenPlayerLostMessage);
    yield fork(listenRoundStartedMessage);
    yield fork(listenDealerChangedMessage);
    yield fork(listenDrawFinishedMessage);
    yield fork(listenResultConfirmedMessage);
    yield fork(listenBettingTimeStartedMessage);
    yield fork(listenBettingTimeFinishedMessage);
    yield fork(listenDrawStartedMessage);
    yield fork(listenRoundCanceledMessage);
    yield fork(listenTableClosedMessage);
    yield fork(listenCloseTableNotificationMessage);
    yield fork(listenCloseGameNoBetsNotificationMessage);
    yield fork(listenDisplayWinnersMessage);
    yield fork(listenRoundFinishedMessage);
    yield fork(listenPendingBetsMadeMessage);
    yield fork(listenConnectionFailedMessage);
    yield fork(listenSocketСonnectedMessage);
    yield fork(listenSocketDisconnectedMessage);
    yield fork(listenJackpotCreated);
    yield fork(listenJackpotStageStarted);
    yield fork(listenJackpotFinished);
    yield fork(listenJackpotUpdated);
    yield fork(listenJackpotDetailsUpdated);
    yield fork(listenJackpotPaused);
    yield fork(listenJackpotUnpaused);
    yield fork(listenJackpotCollectionStarted);
    yield fork(listenJackpotStateUpdated);
    yield fork(listenJackpotCollectionFinished);
    yield fork(listenJackpotPlayerPoints);
    yield fork(listenJackpotPointAwarded);
  }
}

import { ClientSideNotifications, INotificationResponse, Notifications } from 'types';
import { pull } from 'lodash';
import { io, Socket } from 'socket.io-client';
import { clienSideConnectionListeners, defaultListeners } from 'constants/webSocket';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PlatformType } from 'hooks';

class WebSocketService {
  ws: Socket | null = null;

  connectInterval: number | null = null;

  pingInterval: number | null = null;

  timeout = 250;

  listeners: { [key in Notifications]: Array<(notification: INotificationResponse<any>) => void> } =
    defaultListeners as {
      [key in Notifications]: Array<(notification: INotificationResponse<any>) => void>;
    };

  socketConnectionClientListeners: {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  } = clienSideConnectionListeners as {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  };

  manuallyClosed = false;

  connect = (
    sessionId: string,
    // lang: string,
    // tableId: string,
    // gameType: string,
    // userKey: string,
    // token: string,
    device: PlatformType,
    // brand: string,
  ) => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL || 'wss://player-websocket.com/player', {
      transports: ['websocket'],
      query: {
        device,
        sessionId,
      },
    });

    socket.on('connect', () => {
      console.log('Websocket connected');
      this.ws = socket;
      this.manuallyClosed = false;

      if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }
      this.pingInterval = setInterval(() => {
        this.sendMessage({
          type: 'PING',
        });
      }, 30000) as any;

      this.timeout = 250;
      if (this.connectInterval) {
        clearTimeout(this.connectInterval);
      }
    });

    socket.on('disconnect', () => {
      console.log('Websocket closed');

      if (!this.manuallyClosed) {
        this.timeout += this.timeout;
        this.connectInterval = setTimeout(
          () => this.retry(sessionId, device),
          Math.min(10000, this.timeout),
        ) as any;
      }

      this.listeners = defaultListeners as {
        [key in Notifications]: Array<(notification: INotificationResponse<any>) => void>;
      };

      if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }
    });

    socket.on('error', (err) => {
      console.log('Websocket error', err);

      socket.close();
    });

    socket.on('connect_error', (err) => {
      console.log('Connect error: ', err);
    });

    Object.keys(this.listeners).forEach((listenerKey) =>
      socket.on(listenerKey, (event) => {
        this.listeners[listenerKey as Notifications].forEach((listener) => listener(event));
      }),
    );

    Object.keys(this.socketConnectionClientListeners).forEach((listenerKey) =>
      socket.on(listenerKey, () => {
        this.socketConnectionClientListeners[listenerKey as ClientSideNotifications].forEach(
          (listener) => listener(listenerKey),
        );
      }),
    );
  };

  onMessage(listener: (notification: INotificationResponse<any>) => void, type: Notifications) {
    this.listeners[type].push(listener);
    return () => {
      pull(this.listeners[type], listener);
    };
  }

  onClientMessage(listener: (notification: string) => void, type: ClientSideNotifications) {
    this.socketConnectionClientListeners[type].push(listener);
    return () => {
      pull(this.socketConnectionClientListeners[type], listener);
    };
  }

  sendMessage(message: { type: string; data?: object }) {
    console.log('Websocket send message', message);
    const { ws } = this;
    return new Promise((resolve) => {
      if (ws) {
        ws.emit(message.type, message, (response: INotificationResponse<any>) => resolve(response));
      }
    });
  }

  retry = (
    sessionId: string,
    // lang: string,
    // tableId: string,
    // gameType: string,
    // userKey: string,
    // token: string,
    device: PlatformType,
    // brand: string,
  ) => {
    console.log('Websocket retry connect');
    const access_token = localStorage.getItem('access_token');
    const { ws } = this;
    if (!ws || ws.connected === false) {
      if (access_token) {
        this.connect(sessionId, device);
      }
    }
  };

  isConnected() {
    return this.ws && this.ws.connected === true;
  }

  disconnect() {
    if (this.ws) {
      this.manuallyClosed = true;
      this.ws.close();
    }
  }
}

export const webSocketService = new WebSocketService();

export interface IAuthData {
  tableId?: string;
  gameType?: string;
  userKey?: string;
  token?: string;
  sessionId?: string;
  operatorId?: string;
  // username?: string;
}

export interface IBrand {
  brand?: string;
}

export enum ModeGame {
  HOLI = 'holi',
  IFRAME = 'iframe',
}

export interface IAuthState {
  username: string | null;
  uid: string | null;
  currency: string | null;
  mode: ModeGame;
  jackpotOn: boolean;
  lang?: string | null;
  operatorId: string | null;
  isMobileLandscape?: boolean;
}

export enum ACTIONS {
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_MODE = 'SET_USER_MODE',
  SET_USER_LANG = 'SET_USER_LANG',
  SET_DEVICE_ORIENTATION = 'SET_DEVICE_ORIENTATION',
}

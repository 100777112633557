import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toastSelector } from 'core/widgets/Toast';
import { removeToastAction } from 'core/widgets/Toast/actions';
import {
  DesktopComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
  MobileComponent,
} from 'components';
import { Device } from 'types';
import { betMessages, roundStatusMessages } from 'constants/toastMessages';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';

import { SoundButton } from './SoundButton';
import { ToastResponsive } from './Responsive';
import AudioService from 'services/AudioService';
import { Sounds } from 'core/widgets/SoundSettings/types';

export const Toast = () => {
  const dispatch = useDispatch();

  const { toastItem } = useSelector(toastSelector);

  const { gameSoundMute, soundEffects, digitalVoice } = useSelector(soundSettingsSelector);

  useEffect(() => {
    if (toastItem) {
      const timer = setTimeout(() => {
        dispatch(removeToastAction());
      }, toastItem.value.displayTime);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [toastItem, dispatch]);

  const goAudioAlert = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('alert', soundEffects.volume);
    }
  }, [soundEffects, gameSoundMute]);

  const goAudioBallWasDrawn = useCallback(() => {
    AudioService.enqueue('ballWasDrawn', Sounds.DIGITAL_VOICE, true);
  }, []);

  const goAudioPlaceBets = useCallback(() => {
    AudioService.enqueue('placeBets', Sounds.DIGITAL_VOICE, true);
  }, []);

  const goAudioNoMoreBets = useCallback(() => {
    AudioService.enqueue('noMoreBets', Sounds.DIGITAL_VOICE, true);
  }, []);

  useEffect(() => {
    if (
      toastItem?.value === betMessages.ACCEPTED ||
      toastItem?.value === betMessages.MIN_BET_REACHED ||
      toastItem?.value === betMessages.MAX_BET_REACHED ||
      toastItem?.value === betMessages.LOW_BALANCE
    ) {
      goAudioAlert();
    }
    if (toastItem?.value === roundStatusMessages.DRAW_FINISHED) {
      goAudioBallWasDrawn();
    }

    if (toastItem?.value === roundStatusMessages.BETTING_TIME_STARTED) {
      goAudioPlaceBets();
    }

    if (toastItem?.value === roundStatusMessages.BETTING_TIME_FINISHED) {
      goAudioNoMoreBets();
    }
  }, [toastItem?.value, goAudioAlert, goAudioBallWasDrawn, goAudioPlaceBets, goAudioNoMoreBets]);

  return (
    <>
      {Boolean(toastItem) && (
        <ToastResponsive type={toastItem?.type} message={toastItem?.value.text as string} />
      )}
      <DesktopComponent>
        <SoundButton deviceType={Device.Desktop} />
      </DesktopComponent>
      <TabletLandscapeComponent>
        <SoundButton deviceType={Device.Tablet_Landscape} />
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <SoundButton deviceType={Device.Tablet_Portrait} />
      </TabletPortraitComponent>
      <MobileComponent>
        <SoundButton deviceType={Device.Mobile} />
      </MobileComponent>
    </>
  );
};

import { isIOS, osVersion } from 'react-device-detect';
import UAParser from 'ua-parser-js';

export const getDefineDevEnv = () => {
  const searchTerm = 'https://dev-pui.';
  const currentUrl = window?.location?.href;
  const searchTermProd = 'https://holi.';
  const searchTermStage = 'https://stage-pui.';
  const searchTermUat = 'https://uat-pui.';

  return currentUrl.includes(searchTerm)
    ? 'dev'
    : currentUrl.includes(searchTermProd)
      ? 'prod'
      : currentUrl.includes(searchTermStage)
        ? 'stage'
        : currentUrl.includes(searchTermUat)
          ? 'uat'
          : 'local';
};

export const isOldBrowserIOS = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  if (result?.browser?.name === 'MIUI Browser') {
    return true;
  }

  if (result?.browser?.name === 'Huawei Browser') {
    return true;
  }

  if (result?.browser?.name === 'UCBrowser') {
    return true;
  }

  return isIOS && Number(osVersion.split('.')[0]) < 15;
};

export const isInIframe = () => window.self !== window.top;

export enum AspectRatioIphone {
  RATIO_16_9 = '16:9',
  RATIO_19_5_9 = '19.5:9',
  RATIO_21_9 = '21:9',
  RATIO_4_3 = '4:3',
}

export const getAspectRatioIphone = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const ratio = width / height;

  if (Math.abs(ratio - 16 / 9) < 0.01) {
    return AspectRatioIphone.RATIO_16_9;
  } else if (Math.abs(ratio - 19.5 / 9) < 0.01) {
    return AspectRatioIphone.RATIO_19_5_9;
  } else if (Math.abs(ratio - 21 / 9) < 0.01) {
    return AspectRatioIphone.RATIO_21_9;
  } else if (Math.abs(ratio - 4 / 3) < 0.01) {
    return AspectRatioIphone.RATIO_4_3;
  } else {
    return 'Unknown aspect ratio';
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { IconNames, SettingsPanel } from 'components';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { Wrapper } from './styles';
import { IDeviceMainSettingsPanelComponentProps } from './types';
import { MainSettingsItem } from './components';

export const MainSettingsPanelPortrait = ({
  onCloseClick,
  deviceType = Device.Mobile,
}: IDeviceMainSettingsPanelComponentProps) => {
  const { t } = useTranslation();

  const { gameSoundMute } = useSelector(soundSettingsSelector);

  const dispatch = useDispatch();
  const selectSettingsSection = (settingsPanel: SettingPanels) => {
    dispatch(manageSettingsPanelAction({ settingsPanel }));
  };

  return (
    <Wrapper>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType}>
        <SettingsPanel.Title>{t('settings.title')}</SettingsPanel.Title>
        <MainSettingsItem
          icon={gameSoundMute ? IconNames.NoSound : IconNames.Sound}
          text={t('settings.sound.title')}
          onClick={() => selectSettingsSection(SettingPanels.Sound)}
        />
        <MainSettingsItem
          icon={IconNames.Video}
          text={t('settings.video.title')}
          onClick={() => selectSettingsSection(SettingPanels.Video)}
        />
        <MainSettingsItem
          icon={IconNames.General}
          text={t('settings.general.title')}
          onClick={() => selectSettingsSection(SettingPanels.General)}
        />
      </SettingsPanel>
    </Wrapper>
  );
};

import React from 'react';
import { Device, IJackpotSlot } from 'types';
import { isIOS } from 'react-device-detect';
import { Wrapper } from './styles';
import { JackpotTitleBaccaratBrand } from './components/JackpotTitleBaccaratBrand';
import { JackpotAmount } from './components/JackpotAmount';

export const SlotJackpotTablet = ({ jackpotSum, isActiveSlot, onClick }: IJackpotSlot) => (
  <Wrapper
    $deviceType={Device.Tablet_Portrait}
    xmlns="http://www.w3.org/2000/svg"
    width="720"
    height="104"
    viewBox="0 0 720 104"
    x="0"
    y="0"
    fill="none"
    onClick={onClick}>
    {isActiveSlot ? (
      <g filter="url(#filter0_biii_719_62384)">
        <path
          d="M688 0C705.673 0 720 14.3269 720 32V104H0V32C0 14.3269 14.3269 0 32 0H372.869H688Z"
          fill="#121011"
        />
        <path
          d="M688 0C705.673 0 720 14.3269 720 32V104H0V32C0 14.3269 14.3269 0 32 0H372.869H688Z"
          fill="url(#paint0_linear_719_62384)"
        />
        <path
          d="M719 32V103H1V32C1 14.8792 14.8792 1 32 1H372.869H688C705.121 1 719 14.8792 719 32Z"
          stroke="url(#paint1_linear_719_62384)"
          strokeWidth="2"
        />
        <path
          d="M719 32V103H1V32C1 14.8792 14.8792 1 32 1H372.869H688C705.121 1 719 14.8792 719 32Z"
          stroke="url(#paint2_linear_719_62384)"
          strokeWidth="2"
        />
      </g>
    ) : (
      <>
        <g filter="url(#filter0_i_518_6467)">
          <path
            d="M688 0C705.673 0 720 14.3269 720 32V104H0V32C0 14.3269 14.3269 0 32 0H372.869H688Z"
            fill="#121011"
          />
          <path
            d="M688 0C705.673 0 720 14.3269 720 32V104H0V32C0 14.3269 14.3269 0 32 0H372.869H688Z"
            fill="url(#paint0_linear_518_6467)"
          />
        </g>
        <path
          d="M688 1C705.121 1 719 14.8792 719 32V103H1V32C1 14.8792 14.8792 1 32 1H372.869H688Z"
          stroke="url(#paint1_linear_518_6467)"
          strokeWidth="2"
        />
        <path
          d="M688 1C705.121 1 719 14.8792 719 32V103H1V32C1 14.8792 14.8792 1 32 1H372.869H688Z"
          stroke="url(#paint2_linear_518_6467)"
          strokeWidth="2"
        />
      </>
    )}

    {isActiveSlot ? (
      <defs>
        <filter
          id="filter0_biii_719_62384"
          x="-5.65752"
          y="-8"
          width="731.315"
          height="117.658"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_719_62384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_719_62384"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.82876" />
          <feGaussianBlur stdDeviation="8.48628" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_719_62384" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-8" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.356863 0 0 0 0 0.0627451 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_719_62384"
            result="effect3_innerShadow_719_62384"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.72549 0 0 0 0 0 0 0 0 0 0.980392 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_719_62384"
            result="effect4_innerShadow_719_62384"
          />
        </filter>
        <linearGradient
          id="paint0_linear_719_62384"
          x1="265.815"
          y1="0"
          x2="265.815"
          y2="104"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="1" stopColor="#343434" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_719_62384"
          x1="360"
          y1="0"
          x2="360"
          y2="104"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_719_62384"
          x1="720"
          y1="52"
          x2="0"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E6D2" />
          <stop offset="0.5" stopColor="#FFF7EB" />
          <stop offset="1" stopColor="#F1E6D2" />
        </linearGradient>
      </defs>
    ) : (
      <defs>
        <filter
          id="filter0_i_518_6467"
          x="0"
          y="0"
          width="720"
          height="105"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_518_6467" />
        </filter>
        <linearGradient
          id="paint0_linear_518_6467"
          x1="265.815"
          y1="0"
          x2="265.815"
          y2="104"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="1" stopColor="#343434" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_518_6467"
          x1="360"
          y1="0"
          x2="360"
          y2="104"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_518_6467"
          x1="720"
          y1="52"
          x2="0"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E6D2" />
          <stop offset="0.5" stopColor="#FFF7EB" />
          <stop offset="1" stopColor="#F1E6D2" />
        </linearGradient>
      </defs>
    )}
  </Wrapper>
);

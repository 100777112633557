import { ActionType, createReducer } from 'typesafe-actions';
import {
  applyVideoAutoAdjustAction,
  applyVideoPlaybackAction,
  applyVideoQualityAction,
  changeVideoAutoAdjustAction,
  changeVideoPlaybackAction,
  changeVideoQualityAction,
  changeVideoQualityFailAction,
  setStreamNamesAction,
} from './action';
import { IVideoSettingsState, VideoQualities } from './types';

export const videoSettingsState: IVideoSettingsState = {
  streamNames: null,
  requested: {
    playback: true,
    autoAdjust: true,
    quality: VideoQualities.High,
  },
  applied: {
    playback: true,
    autoAdjust: true,
    quality: VideoQualities.High,
  },
  isQualityLoading: false,
};

const actions = {
  setStreamNamesAction,
  changeVideoPlaybackAction,
  applyVideoPlaybackAction,
  changeVideoAutoAdjustAction,
  applyVideoAutoAdjustAction,
  changeVideoQualityAction,
  changeVideoQualityFailAction,
  applyVideoQualityAction,
};

export const videoSettingsReducer = createReducer<IVideoSettingsState, ActionType<typeof actions>>(
  videoSettingsState,
)
  .handleAction(setStreamNamesAction, (state, { payload }) => ({
    ...state,
    streamNames: payload,
  }))
  .handleAction(changeVideoPlaybackAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      playback: payload,
    },
  }))
  .handleAction(applyVideoPlaybackAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      playback: payload,
    },
    applied: {
      ...state.applied,
      playback: payload,
    },
  }))
  .handleAction(changeVideoAutoAdjustAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      autoAdjust: payload,
    },
    // TODO: remove applying, use player api to apply settings
    applied: {
      ...state.applied,
      autoAdjust: payload,
    },
  }))
  .handleAction(applyVideoAutoAdjustAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      autoAdjust: payload,
    },
    applied: {
      ...state.applied,
      autoAdjust: payload,
    },
  }))
  .handleAction(changeVideoQualityAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      quality: payload,
    },
    isQualityLoading: true,
  }))
  .handleAction(changeVideoQualityFailAction, (state) => ({
    ...state,
    requested: {
      ...state.requested,
      quality: state.applied.quality,
    },
    isQualityLoading: false,
  }))
  .handleAction(applyVideoQualityAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      quality: payload,
    },
    applied: {
      ...state.requested,
      quality: payload,
    },
    isQualityLoading: false,
  }));

import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isFirefox } from 'react-device-detect';

export const TimeLabel = styled.span`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const Wrapper = styled.div<{ $deviceType: Device; $hasJP: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 3;

  ${({ $deviceType, $hasJP }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        bottom: ${$hasJP ? '252rem' : '230rem'};
        right: 24rem;
        height: 80rem;
        width: 80rem;

        ${TimeLabel} {
          height: 80rem;
          width: 80rem;
          font-size: 24rem;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 314rem;
        right: 24rem;
        height: 80rem;
        width: 80rem;

        ${TimeLabel} {
          height: 80rem;
          width: 80rem;
          font-size: 24rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        right: 8rem;
        /* top: 151rem;
        top: 142rem; */
        top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '142rem'
          : '151rem'};
        height: 40rem;
        width: 40rem;

        @media only screen and (max-width: 430px) {
          transform: scale(0.8);
          top: ${isOldBrowserIOS() ? '134rem' : '151rem'};
        }

        ${TimeLabel} {
          height: 40rem;
          width: 40rem;
          font-size: 12rem;
        }
      `;
    }
  }};
`;

export const PathRemaining = styled.path<{
  $timeLeft: number;
  $bettingTime: number;
  $timeLeftOnUserReturn: number | null;
  $alertTimeLeft: number;
}>`
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: ${isFirefox ? '1.2s linear all' : '1s linear all'};
  fill-rule: nonzero;
  stroke: url(#color_progress_bar);

  ${({ $timeLeft, $alertTimeLeft }) =>
    $timeLeft <= $alertTimeLeft &&
    css`
      stroke: red;
    `};

  ${({ $timeLeft, $bettingTime, $timeLeftOnUserReturn }) =>
    ($timeLeft === $bettingTime || $timeLeft === $timeLeftOnUserReturn) &&
    css`
      transition: none;
    `};
`;

export const TimerSvg = styled.svg`
  transform: scaleX(-1);
`;

export const TimerCircle = styled.g`
  fill: none;
  stroke: none;
`;

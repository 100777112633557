import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const LoaderWrapper = styled.div`
  position: relative;
  margin: auto;
  z-index: 1;
`;

export const Wrapper = styled.div<{ $deviceType: Device }>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-bottom: 58rem;

        ${LoaderWrapper} {
          height: 64rem;
          width: 64rem;
        }

        ${TryingToReload} {
          font-size: 18rem;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        z-index: 1;
        position: relative;
        width: 128%;
        height: ${isOldBrowserIOS() ? '570rem' : 'auto'};
        aspect-ratio: 16 / 9;
        overflow: hidden;
        position: relative;
        left: -16%;

        ${LoaderWrapper} {
          height: 64rem;
          width: 64rem;
          margin-top: 18%;
          margin-left: 49%;
        }

        ${TryingToReload} {
          font-size: 16rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        z-index: 1;
        position: relative;
        width: 140%;
        /* height: 180rem; */
        height: ${isOldBrowserIOS()
          ? isInIframe()
            ? '270rem'
            : getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
              ? '270rem'
              : '220rem'
          : 'auto'};

        aspect-ratio: 16 / 9;
        overflow: hidden;
        position: relative;
        left: -21%;

        ${LoaderWrapper} {
          height: 24rem;
          width: 24rem;
          margin-top: 20%;
          @media only screen and (max-width: 430px) {
            margin-top: 11%;
          }
        }

        ${TryingToReload} {
          font-size: 12rem;
        }
      `;
    }
  }}
`;

export const TryingToReload = styled.div`
  position: relative;
  top: -18%;
  text-align: center;
  color: var(--light-gray-686868, #686868);
`;

import { createAction } from 'typesafe-actions';
import { ACTIONS, ISoundSettings, Sounds } from './types';

export const changeSoundVolumeAction = createAction(ACTIONS.CHANGE_VOLUME)<{
  volume: number;
  type: Sounds;
}>();

export const applySoundVolumeAction = createAction(ACTIONS.APPLY_VOLUME)<number>();

export const changeSoundMuteAction = createAction(ACTIONS.CHANGE_MUTE)<boolean>();

export const applySoundMuteAction = createAction(ACTIONS.APPLY_MUTE)<{
  mute: boolean;
  type: Sounds;
}>();

export const initSoundAction = createAction(ACTIONS.INIT_SOUNDS)<ISoundSettings>();

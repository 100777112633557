import { createAction } from 'typesafe-actions';
import { PlatformType } from 'hooks';
import { IAuthData } from '../Auth/types';
import { ACTIONS } from './types';

export const connectToWebSocketAction = createAction(ACTIONS.CONNECT_TO_WEBSOCKET)<{
  authData: IAuthData | null;
  platformType: PlatformType;
  brand?: string | null;
}>();

export const connectToWebSocketSuccessAction = createAction(ACTIONS.CONNECT_TO_WEBSOCKET_SUCCESS)();

export const connectToWebSocketFailureAction = createAction(ACTIONS.CONNECT_TO_WEBSOCKET_FAILURE)();

export const socketСonnectedAction = createAction(ACTIONS.SOCKET_CONNECTED)();

export const socketDisconnectedAction = createAction(ACTIONS.SOCKET_DISCONNECTED)();

import audioVoiceRed from 'assets/sounds/voices/english_new/red_wins.wav';
import audioVoiceRedFr from 'assets/sounds/voices/french/red_wins.wav';
import audioVoiceRedCh from 'assets/sounds/voices/chinese/red_wins.wav';
import audioVoiceRedSp from 'assets/sounds/voices/spanish/red_wins.wav';
import audioVoiceRedRu from 'assets/sounds/voices/russian_new/red_wins.wav';
import audioVoiceRedHi from 'assets/sounds/voices/hindi/red_wins.wav';
import audioVoiceRedPt from 'assets/sounds/voices/portuguese/red_wins.wav';
import audioVoiceRedTl from 'assets/sounds/voices/tagalog/red_wins.wav';
import audioVoiceRedId from 'assets/sounds/voices/bahasa/red_wins.wav';

import audioVoiceBlue from 'assets/sounds/voices/english_new/blue_wins.wav';
import audioVoiceBlueFr from 'assets/sounds/voices/french/blue_wins.wav';
import audioVoiceBlueCh from 'assets/sounds/voices/chinese/blue_wins.wav';
import audioVoiceBlueSp from 'assets/sounds/voices/spanish/blue_wins.wav';
import audioVoiceBlueRu from 'assets/sounds/voices/russian_new/blue_wins.wav';
import audioVoiceBlueHi from 'assets/sounds/voices/hindi/blue_wins.wav';
import audioVoiceBluePt from 'assets/sounds/voices/portuguese/blue_wins.wav';
import audioVoiceBlueTl from 'assets/sounds/voices/tagalog/blue_wins.wav';
import audioVoiceBlueId from 'assets/sounds/voices/bahasa/blue_wins.wav';

import audioVoiceGold from 'assets/sounds/voices/english_new/gold_wins.wav';
import audioVoiceGoldFr from 'assets/sounds/voices/french/gold_wins.wav';
import audioVoiceGoldCh from 'assets/sounds/voices/chinese/gold_wins.wav';
import audioVoiceGoldSp from 'assets/sounds/voices/spanish/gold_wins.wav';
import audioVoiceGoldRu from 'assets/sounds/voices/russian_new/gold_wins.wav';
import audioVoiceGoldHi from 'assets/sounds/voices/hindi/gold_wins.wav';
import audioVoiceGoldPt from 'assets/sounds/voices/portuguese/gold_wins.wav';
import audioVoiceGoldTl from 'assets/sounds/voices/tagalog/gold_wins.wav';
import audioVoiceGoldId from 'assets/sounds/voices/bahasa/gold_wins.wav';

import audioVoiceWin from 'assets/sounds/voices/english_new/congratulations_to_winners.wav';
import audioVoiceWinFr from 'assets/sounds/voices/french/congratulations_to_the_winners.wav';
import audioVoiceWinCh from 'assets/sounds/voices/chinese/congratulations_to_the_winners.wav';
import audioVoiceWinSp from 'assets/sounds/voices/spanish/congratulations_to_the_winners.wav';
import audioVoiceWinRu from 'assets/sounds/voices/russian_new/congratulations_to_the_winners.wav';
import audioVoiceWinHi from 'assets/sounds/voices/hindi/congratulations_to_the_winners.wav';
import audioVoiceWinPt from 'assets/sounds/voices/portuguese/congratulations_to_the_winners.wav';
import audioVoiceWinTl from 'assets/sounds/voices/tagalog/congratulations_to_the_winners.wav';
import audioVoiceWinId from 'assets/sounds/voices/bahasa/congratulations_to_the_winners.wav';

import { LANGUAGES } from 'i18n';

export const redSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceRed,
  [LANGUAGES.zh]: audioVoiceRedCh,
  [LANGUAGES.fr]: audioVoiceRedFr,
  [LANGUAGES.es]: audioVoiceRedSp,
  [LANGUAGES.ru]: audioVoiceRedRu,
  [LANGUAGES.hi]: audioVoiceRedHi,
  [LANGUAGES.pt]: audioVoiceRedPt,
  [LANGUAGES.tl]: audioVoiceRedTl,
  [LANGUAGES.id]: audioVoiceRedId,
};

export const goldSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceGold,
  [LANGUAGES.zh]: audioVoiceGoldCh,
  [LANGUAGES.fr]: audioVoiceGoldFr,
  [LANGUAGES.es]: audioVoiceGoldSp,
  [LANGUAGES.ru]: audioVoiceGoldRu,
  [LANGUAGES.hi]: audioVoiceGoldHi,
  [LANGUAGES.pt]: audioVoiceGoldPt,
  [LANGUAGES.tl]: audioVoiceGoldTl,
  [LANGUAGES.id]: audioVoiceGoldId,
};

export const blueSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceBlue,
  [LANGUAGES.zh]: audioVoiceBlueCh,
  [LANGUAGES.fr]: audioVoiceBlueFr,
  [LANGUAGES.es]: audioVoiceBlueSp,
  [LANGUAGES.ru]: audioVoiceBlueRu,
  [LANGUAGES.hi]: audioVoiceBlueHi,
  [LANGUAGES.pt]: audioVoiceBluePt,
  [LANGUAGES.tl]: audioVoiceBlueTl,
  [LANGUAGES.id]: audioVoiceBlueId,
};

export const congratulationsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceWin,
  [LANGUAGES.zh]: audioVoiceWinCh,
  [LANGUAGES.fr]: audioVoiceWinFr,
  [LANGUAGES.es]: audioVoiceWinSp,
  [LANGUAGES.ru]: audioVoiceWinRu,
  [LANGUAGES.hi]: audioVoiceWinHi,
  [LANGUAGES.pt]: audioVoiceWinPt,
  [LANGUAGES.tl]: audioVoiceWinTl,
  [LANGUAGES.id]: audioVoiceWinId,
};
